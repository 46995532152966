import { connect } from 'react-redux';
import utils from 'utils';
import LOCATIONS from 'constants/locations';
import MODAL from 'constants/modals';
import { MODAL_PATH } from 'reducers/modal';
import { breakpointSelector } from 'selectors/breakpoint';
import {
  getModifyFlag,
  debitPaymentObjectSelector,
  contractDetailsSelector,
  guaranteedResCardRequiredSelector,
  getPreselectedReservationBillingAccount,
  getIsDeeplink,
  getPreSelectedAdditionalInfoQuestions,
  showEmailSpecialsSelector,
  setRequestEmailSpecialSelector,
} from 'selectors/reservationSelectors';
import {
  isLoggedInSelector,
  profileDataSelector,
  getDefaultLoyaltyContractProfileStatus,
} from 'selectors/profileSelectors';
import {
  oppositeVehicleRateSelector,
  vehicleRateSelector,
  selectedCarClassCoupons,
  gmaAdditionalDriversSelector,
  inPathAdditionalDriversSelector,
  getSelectedInResOCISaveTime,
} from 'selectors/reservationFlowSelectors';
import {
  getUiStateLastName,
  getUiStateFirstName,
  getUiCommitStateSelector,
  getAutoCommsConsentOptInSelector,
} from 'selectors/uiStateSelectors';
import {
  getShowConsentToText,
  getAutoCommasSmsImplicitConsentEnabled,
  getSmsOptInEnabled,
} from 'selectors/appSelectors';
import {
  onlineCheckInStatusSelector,
  onlineCheckInProfileSelector,
  showActivatedProfileInResFlow,
} from 'selectors/checkinSelectors';
import { modalRequiredPoliciesSelector, formattedPoliciesSelector } from 'selectors/policiesSelectors';
import { getPreSelectedDriverInfo, driverDetailsSelector, airlineOptionsSelector } from 'selectors/reviewSelectors';
import { selectedExtras, hasLimitedInventoryExtraSelector } from 'selectors/extrasSelectors';
import { isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import { setDriveProfileState } from 'actions/profile';
import { getInPathVehicleUpgradeDetails } from 'actions/reservation/getInPathVehicleUpgradeDetails';
import { prepayFlopCurrentSession } from 'actions/reservation/prepayFlopCurrentSession';
import { updateCommitState } from 'actions/reservation/updateCommitState';
import { setAutoCommsConsent } from 'actions/reservation/setAutoCommsConsent';
import { clearBillingAccount } from 'actions/reservation/clearBillingAccount';
import { reviewCommit } from 'actions/reservation/reviewCommit';
import { removeInflightPaymentDetails } from 'actions/reservation/removeInflightPaymentDetails';
import { addAdditionalDriversOnReservation } from 'actions/reservation/addAdditionalDriversOnReservation';
import { setResCommitAdditionalInformation } from 'actions/reservation/setResCommitAdditionalInformation';
import { openModal } from 'actions/modal/openModal';
import { openModalWithProps } from 'actions/modal/openModalWithProps';
import Review from './Review';

const mapStateToProps = (state) => {
  const reservation = state.getIn(['gmi', 'session', 'gbo', 'reservation']);
  const commitState = state.getIn(['gmi', 'ui', 'reservations', 'commit']);
  const { reservationEmail, reservationPhone, reservationLastName, reservationFirstName, reservationFullName } =
    driverDetailsSelector(state) || {};
  const modalQueue = state.getIn(MODAL_PATH.MODAL_QUEUE);
  const activeModalState = modalQueue.get(0);
  const isOpenInResAcceleratedWizardModal = activeModalState?.get('modalKey') === MODAL.IN_RES_OCI_WIZARD_MODAL;
  const autoCommsSmsOptInValue = getAutoCommsConsentOptInSelector(state);
  const profileData = profileDataSelector(state, { email_update_required: true });

  return {
    reservationReady: !!reservation,
    breakpoint: breakpointSelector(state),
    isAirport: reservation?.getIn(['pickup_location', 'location_type']) === LOCATIONS.TYPE_AIRPORT.toLowerCase(),
    isMultiTerminalAirport: reservation?.getIn(['pickup_location', 'multi_terminal']),
    airlineInfo: reservation?.get('airline_info'),
    isPayLater: !reservation?.get('prepay_selected'),
    isAfterHoursPickup: reservation?.get('after_hours_pickup_selected'),
    vehicleRate: vehicleRateSelector(state),
    oppositeVehicleRate: oppositeVehicleRateSelector(state),
    policies: formattedPoliciesSelector(state),
    airlineOptions: airlineOptionsSelector(state),
    contractDetails: contractDetailsSelector(state),
    commitState,
    status: reservation?.getIn(['car_class_details', 'status']),
    hasLimitedInventoryExtra: hasLimitedInventoryExtraSelector(state),
    extras: selectedExtras(state),
    isTrueModify: getModifyFlag(state),
    collectNewPaymentCard: reservation?.get('collect_new_payment_card_in_modify'),
    paymentCardData: debitPaymentObjectSelector(state),
    reservationEmail,
    reservationPhone,
    reservationLastName,
    reservationFirstName,
    reservationFullName,
    preselectedDriverInfo: getPreSelectedDriverInfo(state),
    additionalInformation: reservation?.get('additional_information'),
    reservationBillingAccount: reservation?.get('billing_account') || getPreselectedReservationBillingAccount(state),
    modalRequiredPolicies: modalRequiredPoliciesSelector(state),
    partner_reward_program: reservation?.get('partner_reward_program'),
    guaranteedResCardRequired: guaranteedResCardRequiredSelector(state),
    selectedCarCoupons: selectedCarClassCoupons(state),
    isDeeplink: getIsDeeplink(state),
    gmaAdditionalDrivers: gmaAdditionalDriversSelector(state),
    gboAdditionalDrivers: inPathAdditionalDriversSelector(state),
    destinationCurrencySelected: isDestinationCurrencySelected(state),
    profile: onlineCheckInProfileSelector(state),
    isSelectedInResOCISaveTime: getSelectedInResOCISaveTime(state),
    onlineCheckInStatus: onlineCheckInStatusSelector(state),
    commitStateFirstName: getUiStateFirstName(state),
    commitStateLastName: getUiStateLastName(state),
    showInResOCIActivatedProfile: showActivatedProfileInResFlow(state),
    isLoggedIn: isLoggedInSelector(state),
    pickupLocation: reservation?.get('pickup_location'),
    returnLocation: reservation?.get('return_location'),
    isOpenInResAcceleratedWizardModal,
    additionalInfoDetails: getPreSelectedAdditionalInfoQuestions(state),
    showSMSConsent: getShowConsentToText(state),
    autoCommsSmsOptInValue,
    reviewUICommitState: getUiCommitStateSelector(state),
    showEuImpliedConsent: getAutoCommasSmsImplicitConsentEnabled(state),
    getSmsOptInEnabled: getSmsOptInEnabled(state),
    isEmailUpdateRequired: profileData?.email_update_required,
    showEmailSpecials: showEmailSpecialsSelector(state),
    requestEmailSpecialState: setRequestEmailSpecialSelector(state),
    hasDefaultLoyaltyContractProfile: getDefaultLoyaltyContractProfileStatus(state),
  };
};

const mapDispatchToProps = {
  setDriveProfileState,
  updateCommitState,
  reviewCommit,
  setAutoCommsConsent,
  showLearnMoreModal: () => openModal(MODAL.RENTAL_DETAILS_LEARN_MORE_MODAL),
  showCurrencyConversionModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
  showPrePayTermsModal: () => openModal(MODAL.PREPAY_TERMS_CONDITIONS_MODAL),
  setGMIUICommitAdditionalInformation: setResCommitAdditionalInformation,
  clearBillingAccount,
  prepayFlopCurrentSession,
  removeInflightPaymentDetails,
  addAdditionalDriversOnReservation,
  openRequiredModal: (props) => openModalWithProps(MODAL.REQUIRED_MODAL_POLICY, props),
  showCouponDoesNotApplyModal: (continueWithoutCoupon) =>
    openModalWithProps(MODAL.COUPON_DOES_NOT_APPLY, { continueWithoutCoupon }),
  showDoNotRentModal: () => openModal(MODAL.DO_NOT_RENT_MODAL),
  getInPathVehicleUpgradeDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(Review));
