import React, { Component } from 'react';
import utils from 'utils';
import cn from 'classnames';
import { ANALYTICS, TOOLTIP_THEMES } from 'constants';
import { capitalize } from 'utils/string';
import Button from 'components/Button';
import TooltipTrigger from './TooltipTrigger';
import ToolTipTemplate from './ToolTipTemplate';
import { propTypes, defaultProps } from './ToolTipPropType';

/**
 * Portal to render mobile tooltips into body
 */
const themeClasses = {
  [TOOLTIP_THEMES.LIGHT_BLUE]: 'tooltips__info-icon--light-blue-theme',
  [TOOLTIP_THEMES.REGULAR_SLATE]: 'tooltips__info-icon--slate-theme',
};

// reusable trigger button
const ToolTipTriggerButton = React.forwardRef(({ triggerTitle, tooltipID, className, ariaLabel, ...props }, ref) => (
  <Button
    plain
    type='button'
    aria-describedby={tooltipID}
    title={triggerTitle}
    aria-label={ariaLabel}
    className={className}
    {...props}
    buttonRef={ref}
  />
));

/*
 * Using https://github.com/mohsinulhaq/react-popper-tooltip
 * Placements for tooltips =>
 * https://github.com/mohsinulhaq/react-popper-tooltip#placement
 */
class ToolTip extends Component {
  constructor(props) {
    super(props);
    // This is for accessibility -> aria-describedby
    const tooltipUniqueID = utils.dom.uuid();
    this.state = {
      showToolTip: false,
      tooltipID: `tooltip-id-${tooltipUniqueID}`,
    };
  }

  static propTypes = propTypes;

  static defaultProps = defaultProps;

  handleAnalytics = () => {
    const { analyticsKey } = this.props;

    if (analyticsKey) {
      // Analytics - fire custom "modal" event (push to interaction object)
      utils.analytics.interaction(
        capitalize(ANALYTICS.UI_TOOLTIP), // type
        analyticsKey, // key
        this.state.showToolTip ? ANALYTICS.CLOSE : ANALYTICS.OPEN // value
      );
    }
    console.log(
      capitalize(ANALYTICS.UI_TOOLTIP),
      analyticsKey,
      this.state.showToolTip ? ANALYTICS.CLOSE : ANALYTICS.OPEN
    );
  };

  handleClick = (e) => {
    this.handleAnalytics();
    e.stopPropagation && e.stopPropagation();
    this.setState((prevState) => ({ showToolTip: !prevState.showToolTip }));
  };

  closeModal = (e) => {
    this.handleAnalytics();

    e.stopPropagation();
    // Removing this code as it triggers twice onBlur
    // this.setState({ showToolTip: false });
  };

  handleClickMainModal = (e) => {
    // When someone clicks the modal we don't want the event to bubble up and trigger other click handlers
    e.stopPropagation();
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown = (e) => {
    const { showToolTip } = this.state;
    // Is ESC keyCode + tooltip is showing
    const key = utils.accessibility.formatKeys(e);
    if (key.escape && showToolTip) {
      this.closeModal();
    }
  };

  toolTipHeader = () => (
    <Button
      plain
      type='button'
      className='tooltips__button-toggle'
      aria-label={utils.i18n('close_tooltip')}
      title={utils.i18n('close_tooltip')}
      tabIndex='-1'
      onClick={this.handleClick}
    />
  );

  render() {
    const { breakpoint, children, placement, theme, triggerTitle } = this.props;
    const { showToolTip, tooltipID } = this.state;
    const themeClass = themeClasses[theme];

    return (
      <TooltipTrigger
        // Where the tooltip should be positioned
        placement={breakpoint.isMobile ? 'auto' : placement}
        // How the tooltip should be triggered
        trigger='click'
        // Used to control the internal state
        onVisibilityChange={this.handleClick}
        tooltipShown={showToolTip}
        // Passing tooltipHeader down so we can close the modal by clicking on the button
        // children will be the content of the modal
        tooltip={ToolTipTemplate(this.toolTipHeader(), children, tooltipID)}
        // for accessibility
        tooltipID={tooltipID}
      >
        {({ triggerRef }) => (
          <ToolTipTriggerButton
            tooltipID={tooltipID}
            onBlur={this.closeModal}
            title={triggerTitle}
            ariaLabel={triggerTitle}
            ref={triggerRef}
            className={cn('tooltips__info-icon', themeClass)}
          />
        )}
      </TooltipTrigger>
    );
  }
}

export default ToolTip;
