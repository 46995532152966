import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { formatPhoneNumber, unformatPhoneNumber } from 'utils/profile';
import { onlineCheckInStatusSelector, getProfileFound } from 'selectors/checkinSelectors';
import { parseCountryInfo } from 'utils/phoneInput';
import { mergeDeep, get } from 'utils/object';
import { FormSpy } from 'react-final-form';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import { useSelector } from 'react-redux';
import FieldControl from 'components/Form/FieldControl';
import CountryList from 'components/Form/CountryList';

import COUNTRY_CODE from 'constants/countryCode';

/**
 * PhoneNumberField Description:
 *
 * @param {object} props - React Props
 * @param {string} props.name: unique field name
 * @param {string} props.id - unique field id
 * @param {string} props.label - a descriptive label for the user to read
 * @param {boolean} props.full - will make the field fill the width of its container
 * @param {boolean} props.error - Show Error State
 * @param {boolean} props.attention - Show Attention State
 * @param {Array | boolean} props.validations
 * @param {string | boolean} props.initialValueButton
 * @param {function} props.initialValueButtonHandler - Handler for remove the initial value button
 * @param {string} props.inputType - Input type passed into FieldControl (i.e. 'text', 'number', 'tel')
 * @return {JSX} ...
 */
const PhoneNumberField = ({ inputType, ...props }) => {
  const uuid = utils.dom.uuid();
  let autoCompleteValue;
  if (props.initialValueButton) {
    autoCompleteValue = `new-${props.name}`;
  } else if (props.autoComplete) {
    autoCompleteValue = props.autoComplete;
  } else {
    autoCompleteValue = 'on';
  }
  const donotFetchStateAndProvinces = true;
  const inputRef = useRef();

  const getCountrySelected = utils.phoneInput.selectedCountryDetails.get();

  let initialCountryCode = utils.config.getDomainCountry();

  const [showList, setShowList] = useState(false);

  const isMaskedPhoneNumber = utils.fieldValidation.isMasked(props?.initialValueButton);

  const [hideCountryFlag, setHideCountryFlag] = useState(isMaskedPhoneNumber);
  const [showCountryFlag, setCountryCountryFlag] = useState(false);

  const onlineCheckInStatus = useSelector(onlineCheckInStatusSelector);
  const { ociComplete, driverProfileComplete, contactProfileComplete } = onlineCheckInStatus;

  const isProfileFound = useSelector(getProfileFound);
  const isUnauthPartialProfileFound =
    isProfileFound &&
    ((driverProfileComplete && !contactProfileComplete) || (contactProfileComplete && !driverProfileComplete));

  const foundCompleteProfile = driverProfileComplete && contactProfileComplete;
  const isCompletedDriverContactInfo = foundCompleteProfile && ociComplete;

  if ((isUnauthPartialProfileFound || !isCompletedDriverContactInfo) && hideCountryFlag && !isMaskedPhoneNumber) {
    initialCountryCode = getCountrySelected?.countryCode;
  }

  const { selectedCountry, setSelectedCountry, countries } = useCountryFieldsConfig(
    initialCountryCode,
    donotFetchStateAndProvinces
  );

  // every subscribed form change triggers this function
  const handleChange = (formState) => {
    const { active, values } = formState;
    let newState = {};

    const index = props.name.replace(/\D/g, '');
    const parseIndex = parseInt(index, 10);

    newState = { ...formState };
    if (!isNaN(parseIndex) && active && active === props.name) {
      // Set checked value if there's a checked item, otherwise change it to false
      const trimPhoneNumberFromStringPath = active?.split(`[${parseIndex}].phone_number`).join('');
      const phoneSet = get(values, trimPhoneNumberFromStringPath);
      if (
        phoneSet?.[parseIndex]?.phone_number?.trimEnd() !== selectedCountry?.country_content?.dialing_code &&
        !utils.fieldValidation.isMasked(phoneSet[parseIndex]?.phone_number)
      ) {
        const updateStorage =
          selectedCountry?.country_content?.dialing_code &&
          (!getCountrySelected?.currentDialingCode ||
            getCountrySelected?.currentDialingCode !== selectedCountry?.country_content?.dialing_code);

        const countryFlagObject =
          selectedCountry?.country_content?.dialing_code && parseCountryInfo(selectedCountry, countries, updateStorage);
        const formatPhone = formatPhoneNumber(phoneSet[0].phone_number, countryFlagObject, false);
        console.log(formatPhone);
        phoneSet[parseIndex] = {
          ...phoneSet[parseIndex],
          phone_number: formatPhone,
          country_code: selectedCountry?.country_code,
          country_name: selectedCountry?.country_name,
          priority: parseIndex + 1,
        };

        newState = mergeDeep(newState.values, phoneSet);
      }
    }

    return { ...newState };
  };

  const handleSelectCountry = (country) => {
    setShowList(false);
    setSelectedCountry(country);
    setTimeout(() => {
      inputRef?.current?.childRef?.current?.focus();
    }, 10);
  };

  const updatePhoneNumber = (value) => {
    if (value && value.length > 0) {
      let phoneNumber = value?.replace(`${selectedCountry?.country_content.dialing_code} `, '');
      phoneNumber = unformatPhoneNumber(phoneNumber);
      if (phoneNumber.length > 10 && [COUNTRY_CODE.US, COUNTRY_CODE.CA].includes(selectedCountry?.country_code)) {
        phoneNumber = phoneNumber.slice(0, 10);
      }
      props?.onChange?.({ country: selectedCountry, phoneNumber });
    }
  };

  const onNumberChange = (e) => {
    const { value } = e?.target || {};
    const dialingCode = selectedCountry?.country_content?.dialing_code;
    if (value === dialingCode) {
      return;
    }
    if (value === '') {
      inputRef.current?.handleChange(dialingCode);
    }

    updatePhoneNumber(e?.target?.value);
  };

  const handleShowCountryFlag = (clearInitialValue, e) => {
    if (clearInitialValue) {
      clearInitialValue();
    }
    setHideCountryFlag(!hideCountryFlag);
    setCountryCountryFlag(true);
    e && inputRef?.current?.childRef?.current?.focus();
  };

  useEffect(() => {
    !hideCountryFlag && updatePhoneNumber(inputRef.current?.props.input?.value);

    if (props.initialValueButton && !hideCountryFlag && !showCountryFlag) {
      handleShowCountryFlag();
    }
  }, [selectedCountry?.country_content?.dialing_code, hideCountryFlag, props.initialValueButton]);

  const updateStorage =
    selectedCountry?.country_content?.dialing_code &&
    (!getCountrySelected?.currentDialingCode ||
      getCountrySelected?.currentDialingCode !== selectedCountry?.country_content?.dialing_code);
  const countryFlagObject =
    selectedCountry?.country_content?.dialing_code && parseCountryInfo(selectedCountry, countries, updateStorage);

  let defaultValue = false;
  if ((isUnauthPartialProfileFound || !isCompletedDriverContactInfo) && !isMaskedPhoneNumber) {
    defaultValue = props.initialValueButton;
  }

  const countryListId = `country-list-${props.name}-${inputRef.current?.props.input?.value}`;

  return (
    <>
      <FieldControl
        {...props}
        ref={inputRef}
        id={props.id || uuid}
        type={inputType}
        autoComplete={autoCompleteValue}
        className='phone-number-field'
        initialValueButton={hideCountryFlag && isMaskedPhoneNumber && props.initialValueButton}
        initialValueButtonHandler={handleShowCountryFlag}
        aria-owns={countryListId}
        aria-controls={countryListId}
        inputMode='numeric'
        tabbingIndex={!hideCountryFlag}
        {...(inputRef.current?.props?.input?.value.length <= 0 &&
          selectedCountry?.country_content?.dialing_code && {
            initialValue: selectedCountry?.country_content?.dialing_code,
          })}
        {...(selectedCountry?.country_content?.dialing_code && {
          format: (value = defaultValue) =>
            formatPhoneNumber(value, countryFlagObject, hideCountryFlag && isMaskedPhoneNumber),
        })}
        applyInputPropsToChildIndex={1}
        onChange={onNumberChange}
      >
        {!hideCountryFlag ||
        ((isUnauthPartialProfileFound || !isCompletedDriverContactInfo) && hideCountryFlag && !isMaskedPhoneNumber) ? (
          <CountryList
            showList={showList}
            setShowList={setShowList}
            countries={countries}
            selectedCountry={selectedCountry}
            handleSelectCountry={handleSelectCountry}
          />
        ) : (
          <></>
        )}
        <input />
      </FieldControl>
      {/* hack for handleChange to get called  before render, referred from here https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
       */}
      <FormSpy
        onChange={(formState) => setTimeout(() => handleChange(formState), 0)}
        subscription={{ active: true, values: true, visited: true, submitFailed: true }}
      />
    </>
  );
};

PhoneNumberField.defaultProps = {
  inputType: 'phone',
};

PhoneNumberField.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  full: PropTypes.bool,
  error: PropTypes.bool,
  attention: PropTypes.bool,
  validations: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  initialValueButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  initialValueButtonHandler: PropTypes.func,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhoneNumberField;
