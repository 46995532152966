import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import Title from 'components/Title';
import utils from 'utils';
import PropTypes from 'prop-types';
import useActions from 'hooks/useActions';
import { actions, utils as globalUtils } from '@ehi/global-marketing-interface';
import { THEMES, MODAL, MODAL_THEMES } from 'constants';
import DISCOUNTS from 'constants/discounts';
import ANALYTICS from 'constants/analytics';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { filterSortInfoSelector, getSelectedCurrencyToggle } from 'selectors/uiStateSelectors';
import SortFilterWrapper from 'components/SortFilterWrapper';
import NoResultsVehicleGrid from 'components/NoResultsVehicleGrid';
import DiscountNotification from 'components/DiscountNotification';
import GenericNotification from 'components/GenericNotification';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import VehicleFilterToggle from 'components/VehicleFilterToggle';
import LimitedInventoryVehicleModalContent from 'components/ReservationFlow/VehicleSelect/Modals/LimitedInventoryVehicleModalContent';
import LoadingWidget from 'components/LoadingWidget';
import LearnAboutPrepayAndPayLaterModal from 'components/modals/LearnAboutPrepayAndPayLaterModal';
import CurrencyConversionModal from 'components/modals/CurrencyConversionModal';
import CouponDoesNotApplyModal from 'components/modals/CouponDoesNotApplyModal';
import NoSpecialsAvailableModal from 'components/modals/NoSpecialsAvailableModal';
import ServiceErrors from 'components/ServiceErrors';
import useClickOutside from 'hooks/useClickOutside';
import VehicleSelectDetailsCard from './VehicleSelectDetailsCard';
import TaxesFeesModalContent from './Modals/TaxesFeesModalContentContainer';
import { filters, sorting } from './vehicleSelectFiltersConfig';
import TermsOfUseVanModalContent from './Modals/TermsOfUseVanModalContent';

/**
 * VehicleSelect - VehicleSelect reservation flow main page component
 *
 * @param {object}    props - React Props
 * @param {object}    props.breakpoint -
 * @param {bool}      props.reservationDataAvailable -
 * @param {object}    props.contractDetails -
 * @param {array}     props.vehicles -
 * @param {bool}      props.prepayExists -
 * @param {func}      props.handlePrepayTermsModal -
 * @param {object}    props.lowestCarFilterPrices -
 * @param {func}      props.initVehicleSelectFilters -
 * @param {func}      props.clearFiltersUi -
 * @param {object}    props.selectedVehicle -
 * @param {object}    props.preSelectedVehicle -
 * @param {number}    props.numFiltersSelected -
 * @param {bool}      props.isTrueModify -
 * @param {bool}      props.prepaySelected -
 * @param {array}     props.coupons - array of coupons from gbo reservation object indicating coupons applied on initiate
 * @param {object}    props.vehiclesHaveCoupon - object with flags about coupon status for vehicle class list
 * @param {bool}      props.vehiclesHaveCoupon.all - all car classes have coupon applied
 * @param {bool}      props.vehiclesHaveCoupon.none - none of the car classes have coupon applied
 * @param {bool}      props.guaranteedVehiclesAvailable - true if any vehicles are guaranteed_vehicles
 * @param {bool}      props.hasCarClasses - true if there are car classes inside gbo reservation
 * @param {bool}      props.isEuropeanUnionCountry - true if its a EU Domain
 * @param {bool}      props.isLastMinuteSpecials - true if contract is LMS
 * @param {bool}      props.isPlanAheadSpecials - true if contract is plan_ahead_specials
 * @return {JSX}      VehicleSelect component
 */
const VehicleSelect = ({
  reservationDataAvailable,
  hasCarClasses,
  hasFilters,
  breakpoint,
  vehicles,
  lowestCarFilterPrices,
  contractDetails,
  prepayExists,
  selectedVehicle,
  preSelectedVehicle,
  numFiltersSelected,
  isTrueModify,
  prepaySelected,
  coupons,
  vehiclesHaveCoupon,
  isEuropeanUnionCountry,
  isLastMinuteSpecials,
  handlePrepayTermsModal,
  guaranteedVehiclesAvailable,
  initVehicleSelectFilters,
  clearFiltersUi,
  allVehicles,
  isPlanAheadSpecials,
  storeKey,
  openNoSpecialsAvailableModal,
  vehicleSearchFilters,
  setSelectedCurrencyToggleData,
  isPayTypeSelected,
  destinationCurrencyCode,
  currencyToggleData,
  showCurrencyConversionModal,
  filteredItems,
  showDriveTypeFilter,
  hasDefaultLoyaltyContractProfile,
}) => {
  const showCurrencyToggle = currencyToggleData?.showCurrencyToggleUI;
  const selectedCurrencyToggle = useSelector((state) => getSelectedCurrencyToggle(state));
  const sortInfo = useSelector((state) => filterSortInfoSelector(state, storeKey));
  const vehicleFilters = React.createRef();
  const sortFilterWrapperRef = useRef();
  const handleOutsideClickSortFilterWrapperRef = useRef();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [specialsNotAvailableModalOpened, setSpecialsNotAvailableModalOpened] = useState(false);
  const { isMobile, isTablet, isTabletOrMobile, isDesktop } = breakpoint;
  const filterSideNavExpanded = filtersOpen && isTabletOrMobile;
  const filterWrapperClass = cn('vehicle-select__filter-wrapper', {
    'vehicle-select__filter-side-nav-expanded': filterSideNavExpanded,
  });
  const shouldRenderFilters = isDesktop || filterSideNavExpanded;
  const setSortByValue = useActions(({ sortKey, isDesc, isNumber, value }) =>
    actions.setSortFilteredItemsByValue(storeKey, sortKey, isDesc, isNumber, value)
  );
  const showFeaturedVehicle = utils.config.getIsEnableFeaturedVehicle();

  // checking for num of filters selected value and available vehicles
  const clearVehicleFiltersUI = vehicles?.length <= 0 && numFiltersSelected <= 0;

  const labels = currencyToggleData?.currencyToggleLabels;

  const initFilters = useCallback(() => {
    const previouslySelectedCode = selectedVehicle?.selected_car_class_code || preSelectedVehicle?.code;
    vehicleFilters?.current?.form?.reset?.();
    const filterCodes = ['CLASS', 'PASSENGERS', 'BAGS', 'TRANSMISSION'];

    if (showDriveTypeFilter) {
      filterCodes.push('DRIVE');
    }

    initVehicleSelectFilters({
      filter_codes: filterCodes,
      previously_selected: previouslySelectedCode,
      show_unavailable: false,
    });
  }, [
    initVehicleSelectFilters,
    preSelectedVehicle?.code,
    selectedVehicle?.selected_car_class_code,
    vehicleFilters,
    showDriveTypeFilter,
  ]);

  const allVehicleAvailableAtPromotion = useMemo(
    () => allVehicles?.every((vehicle) => vehicle.status === DISCOUNTS.AVAILABLE_AT_PROMOTIONAL_RATE),
    [allVehicles]
  );

  const someVehicleAvailableAtPromotion = useMemo(
    () => allVehicles?.some((vehicle) => vehicle.status === DISCOUNTS.AVAILABLE_AT_PROMOTIONAL_RATE),
    [allVehicles]
  );

  const someVehicleAvailableAtContractRate = useMemo(
    () => allVehicles?.some((vehicle) => vehicle.status === DISCOUNTS.AVAILABLE_AT_CONTRACT_RATE),
    [allVehicles]
  );

  const clearFilters = () => {
    sortFilterWrapperRef?.current.clearAllFilter();
    clearFiltersUi();
    setSortByValue({
      sortKey: 'no_sort_by',
      label: utils.i18n('vehicle_sort_by_featured'),
      value: 'featured',
    });
  };

  // clearing the filters when  we have filteredItems.length but the vehicles length is 0
  useEffect(() => {
    if (filteredItems.length > 0 && vehicles.length <= 0) {
      setTimeout(() => {
        clearFilters();
      }, 100);
    }
  }, [clearVehicleFiltersUI]);

  const handleToggleFilter = () => {
    setFiltersOpen(!filtersOpen);
    breakpoint.isMobile && utils.scrollLock({ toggleValue: !filtersOpen });
  };

  const handlePrepayTermModal = () => {
    handlePrepayTermsModal(guaranteedVehiclesAvailable);
  };

  const handleSetViewPriceIn = (chargeType, isLocalOrDestination) => {
    utils.analytics.interaction(ANALYTICS.TOGGLE, ANALYTICS.CURRENCY, isLocalOrDestination);
    setSelectedCurrencyToggleData(chargeType);
  };
  /* showInFlightPrevVehicleUnavailable conditionals always require having a preSelectedVehicle, which should be available
  in modify flows or initiating from vehicle page flow. Then it tests whether there is a selectedVehicle and it is same
  as preSelected which indicates the user is in modify flow and just modified location but hasn't updated to a new vehicle.
  If they don't match, the user has updated their selection and the banner is no longer necessary */
  const showInFlightPrevVehicleUnavailable =
    preSelectedVehicle?.code &&
    (!selectedVehicle || (selectedVehicle && preSelectedVehicle.code === selectedVehicle.code)) &&
    !vehicles?.some((vehicle) => vehicle.code === preSelectedVehicle.code);

  /* Show banner when there is a contract and/or the contract is defaultLoyaltyContract */
  const showCIDBanner = (hasDefaultLoyaltyContractProfile || allVehicleAvailableAtPromotion) && coupons.length === 0;

  /* Show Promotion does not apply banner only when contractDetails.defaultLoyaltyContract is false 
  and there is not at least one car with car_classes.status = DISCOUNTS.AVAILABLE_AT_PROMOTIONAL_RATE or DISCOUNTS.AVAILABLE_AT_CONTRACT_RATE */
  const showPromotionDoesNotApplyBanner =
    !contractDetails?.defaultLoyaltyContract && !someVehicleAvailableAtContractRate && !someVehicleAvailableAtPromotion;

  /* Show Everyday Low Rates Banner only when isLastMinuteSpecials = true 
  and there is not at least one car car_classes.status = DISCOUNTS.AVAILABLE_AT_PROMOTIONAL_RATE */

  const showEverydayLowRatesBanner = (isLastMinuteSpecials || isPlanAheadSpecials) && !someVehicleAvailableAtPromotion;

  const availableVehicles = [];
  const selectedClassesVehicles = [];

  vehicles.forEach((vehicle) => {
    if (
      vehicleSearchFilters?.selectedVehicleCodes &&
      vehicleSearchFilters.selectedVehicleCodes.includes(vehicle?.code) &&
      sortInfo?.inputValue === sorting?.initialValue
    ) {
      selectedClassesVehicles.push(vehicle);
    } else {
      availableVehicles.push(vehicle);
    }
  });

  let sortedVehicles = [...selectedClassesVehicles, ...availableVehicles];

  // To determine whether to show alamo insider savings, check if active contract is default loyalty. If so,
  // check price_differences array for item with type CONTRACT or
  // there is not at least one car with car_classes.status = DISCOUNTS.AVAILABLE_AT_PROMOTIONAL_RATE or DISCOUNTS.AVAILABLE_AT_CONTRACT_RATE.

  let alamoInsidersSavings;
  let promotionAndCouponSavings;

  const couponType = coupons?.[0]?.type;
  const showDiscountCoupons = couponType && couponType !== DISCOUNTS.COUPON_TYPE_UPGRADE;

  if (hasDefaultLoyaltyContractProfile) {
    alamoInsidersSavings = sortedVehicles?.some(
      (item) =>
        item.price_differences?.find((price) => price?.difference_type === DISCOUNTS.CONTRACT_TYPE)
          ?.difference_amount_view
    );

    if (allVehicleAvailableAtPromotion || showDiscountCoupons) {
      promotionAndCouponSavings = sortedVehicles?.some(
        (item) =>
          (showDiscountCoupons &&
            item.price_differences?.find((price) => price?.difference_type === DISCOUNTS.COUPON_TYPE)
              ?.difference_amount_view) ||
          (item.status === DISCOUNTS.AVAILABLE_AT_PROMOTIONAL_RATE &&
            item.price_differences?.find((price) => price?.difference_type === DISCOUNTS.PROMOTION_TYPE)
              ?.difference_amount_view)
      );
    }
  }

  /* 
  - Show Alamo Insiders Rates not available Banner only when contract and/or the contract is defaultLoyaltyContract && no coupons are added.
  - For authenticated user, when another contract or discount code is applied to reservation, 
    user should not see either "Alamo Insider rates are not available' banner  or "Alamo Insider Discounts applied" banner.
  - For unauthenticated user, user should not see either banner
  */
  const showAlamoInsiderSpecialNoBanner =
    vehicles?.length && hasDefaultLoyaltyContractProfile && !alamoInsidersSavings && !promotionAndCouponSavings;

  /* Search Vehicle class Unavailable banner conditionally shown when there are no vehicles available based on vehicle class search results.
     Then it tests whether there is  selectedVehicle or  preSelected which indicates the user is in modify flow.
     This means the user has updated their selection and the banner is no longer necessary
  */
  const showSearchedVehicleClassUnavailable =
    vehicleSearchFilters?.selectedValues &&
    !preSelectedVehicle?.code &&
    !selectedVehicle &&
    vehicles?.length > 0 &&
    !vehicles?.some((vehicle) => vehicleSearchFilters.selectedVehicleCodes.includes(vehicle.code));

  const filtersLostOnReInit = reservationDataAvailable && !hasFilters;

  /*   Sending Analytics interactions for vehicle preferred class banner and no of vehicle class search results
     appeared  */
  const { _analytics = {} } = window;
  const analyticsData = _analytics?.interactions;
  const filterAnalyticsData = globalUtils.isArrayNotEmpty(analyticsData)
    ? analyticsData.filter(
        (item) => item.key === ANALYTICS.PREFERRED_CLASS_COUNT || item.key === ANALYTICS.AVAILABILITY
      )
    : [];
  if (globalUtils.isArrayNotEmpty(vehicles) && !globalUtils.isArrayNotEmpty(filterAnalyticsData)) {
    const VCPreferredClassCount = vehicles.filter(
      (vehicle) =>
        vehicleSearchFilters?.selectedVehicleCodes && vehicleSearchFilters.selectedVehicleCodes.includes(vehicle.code)
    );

    showSearchedVehicleClassUnavailable
      ? utils.analytics.interaction(ANALYTICS.MESSAGE, ANALYTICS.AVAILABILITY, ANALYTICS.NO_SELECTED_CLASS)
      : utils.analytics.interaction(
          ANALYTICS.SEARCH_RESULTS,
          ANALYTICS.PREFERRED_CLASS_COUNT,
          `${globalUtils.isArrayNotEmpty(VCPreferredClassCount) ? VCPreferredClassCount.length : 0}`
        );
  }

  // AWR-8571 - Add an additional object here called "prepay_displayed" with values of "true" or "false"
  // depending on if the prepay button is displayed on any of the vehicles.
  const analyticsReservationObject = _analytics?.gbo?.reservation;

  if (vehicles?.length) {
    const prepayAvailable = vehicles.find((vehicle) => vehicle?.charges?.PREPAY);

    if (analyticsReservationObject) {
      _analytics.gbo.reservation = {
        ...analyticsReservationObject,
        prepay_displayed: prepayAvailable ? 'true' : 'false',
      };
    }
  }

  const pageStatus = document.getElementById('pageStatus');

  useEffect(() => {
    if (hasCarClasses && filtersLostOnReInit) {
      initFilters();
      // reset modal data if it's a new reservation
      setSpecialsNotAvailableModalOpened(false);
    }

    if (showEverydayLowRatesBanner && !specialsNotAvailableModalOpened) {
      openNoSpecialsAvailableModal(isLastMinuteSpecials);
      setSpecialsNotAvailableModalOpened(true);
    }

    // To ensure assistive technology users or to notify that the page has been updated, within Reservation flow
    pageStatus.setAttribute('aria-live', 'polite');
    pageStatus.innerText = utils.i18n('vehicle_select_title');

    setTimeout(() => {
      pageStatus.innerText = '';
      pageStatus.setAttribute('aria-live', 'off');
    }, 15000);
  }, [hasCarClasses, reservationDataAvailable, hasFilters, showSearchedVehicleClassUnavailable]);

  // Set Initial currency toggle value
  useEffect(() => {
    if (vehicles?.length && currencyToggleData?.showCurrencyToggleUI && selectedCurrencyToggle?.currency_code === 0) {
      setSelectedCurrencyToggleData(currencyToggleData?.sourceCurrency);
    }
  }, [currencyToggleData?.showCurrencyToggleUI, selectedCurrencyToggle?.currency_code]);

  const prepayTermsModalButton = prepayExists ? (
    <Button
      onClick={handlePrepayTermModal}
      link={true}
      className='vehicle-select__prepay-info-button'
      ariaText={utils.i18n('learn_about_pre_pay_button')}
      data-dtm-track={`button|payment_options|learn_more`}
    >
      <span>{utils.i18n('learn_about_pre_pay_button')}</span>
    </Button>
  ) : null;

  const editTripDetailsAnchor = (
    <Anchor
      href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW)}
      className='link link--text'
      data-dtm-track={utils.analytics.dtm(ANALYTICS.CARS, ANALYTICS.PROMOTION, ANALYTICS.EDIT_DATES)}
    >
      {utils.i18n('everyday_low_rate_apply_link_label')}
    </Anchor>
  );

  const pricingButton = () =>
    showCurrencyToggle &&
    Object.keys(labels).length > 0 && (
      <div>
        <p className='vehicle-select__subtitle-pricing'>{utils.i18n('vehicle_select_currency_toggle_label')}</p>
        <ul className='vehicle-select__currency-toggle__buttons-container'>
          {Object.keys(labels).map((chargeType) => (
            <li key={chargeType}>
              <Button
                ariaSelected={chargeType === currencyToggleData?.isPayTypeSelected ? 'true' : 'false'}
                onClick={() => {
                  const isLocalOrDestination =
                    chargeType === currencyToggleData?.sourceCurrency ? 'local' : 'destination';
                  handleSetViewPriceIn(chargeType, isLocalOrDestination);
                }}
              >
                {chargeType}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    );

  useClickOutside(
    {
      container: handleOutsideClickSortFilterWrapperRef,
      handler: handleToggleFilter,
      listenerConditional: filtersOpen,
      // only activate the click listener while expanded
    },
    [filtersOpen]
  );

  // Currency conversion based on the selected currency toggle, by default source currency will be selected
  if (
    isPayTypeSelected &&
    currencyToggleData?.sourceCurrency &&
    isPayTypeSelected !== currencyToggleData?.sourceCurrency
  ) {
    sortedVehicles = sortedVehicles.map((vehicle) => {
      const { charges } = vehicle;
      const paylaterCharges = charges?.PAYLATER ? charges.PAYLATER : {};
      const prepayCharges = charges?.PREPAY ? charges?.PREPAY : {};
      if (Object.keys(paylaterCharges).length > 0) {
        paylaterCharges.total_price_view = charges?.PAYLATER?.total_price_payment;
        paylaterCharges.rates[0].unit_amount_view = paylaterCharges?.rates?.[0].unit_amount_payment;
      }
      if (Object.keys(prepayCharges).length > 0) {
        prepayCharges.total_price_view = charges?.PREPAY?.total_price_payment;
        prepayCharges.rates[0].unit_amount_view = prepayCharges?.rates?.[0].unit_amount_payment;
        prepayCharges.savings_view = prepayCharges?.savings_payment;
      }
      charges.PAYLATER = { ...paylaterCharges };
      if (charges.PREPAY) charges.PREPAY = { ...prepayCharges };
      vehicle.charges = { ...charges };
      return {
        ...vehicle,
      };
    });
  }
  const convertedPricesSubTitle = currencyToggleData?.convertedCurrency ? (
    <p className='vehicle-select__subtitle'>
      {utils.i18n('vehicle_select_subtitle_converted_prices_copy')}{' '}
      <a onClick={showCurrencyConversionModal} className='link link--text'>
        {utils.i18n('vehicle_select_subtitle_currency_conversion')}
      </a>
    </p>
  ) : (
    <p className='vehicle-select__subtitle'>{utils.i18n('vehicle_select_subtitle')}</p>
  );

  return (
    <div className='vehicle-select row'>
      <div className='vehicle-select__header'>
        <Title
          type='h1'
          text={utils.i18n(isTrueModify ? 'true_modify_vehicle_select_title' : 'vehicle_select_title')}
        />
        <div className='vehicle-select__sub-title-heading'>
          <div className='vehicle-select__subtitle'>{convertedPricesSubTitle}</div>
          {!isMobile && pricingButton()}
        </div>
        {isMobile && prepayTermsModalButton}
      </div>

      <ServiceErrors />

      {(showInFlightPrevVehicleUnavailable || showSearchedVehicleClassUnavailable) && (
        <GenericNotification
          title={utils.i18n('previous_vehicle_unavailable_title')}
          message={utils.i18n('in_flight_previous_vehicle_unavailable_message', [vehicles?.length || 0])}
        />
      )}

      {vehiclesHaveCoupon.none && (
        <GenericNotification
          title={utils.i18n('coupon_does_not_apply_notification_title')}
          message={utils.i18n('coupon_does_not_apply_notification_message', [coupons[0].description])}
        />
      )}

      {contractDetails && showPromotionDoesNotApplyBanner && !showEverydayLowRatesBanner && (
        <GenericNotification
          messageClass='vehicle-select__promotion_does_not_apply_notification_title'
          title={utils.i18n('promotion_does_not_apply_notification_title')}
          message={utils.i18n('promotion_does_not_apply_notification_message', [contractDetails?.contractName])}
        />
      )}

      {contractDetails && showEverydayLowRatesBanner && (
        <GenericNotification
          messageClass='generic-notification__notifications-on'
          title={utils.i18n('everyday_low_rate_apply_title', [
            (isLastMinuteSpecials && utils.i18n('last_minute_special')) ||
              (isPlanAheadSpecials && utils.i18n('plan_ahead_special')),
          ])}
          message={utils.i18n('everyday_low_rate_apply_vehicle_message', [editTripDetailsAnchor], { jsx: true })}
        />
      )}

      {contractDetails && showAlamoInsiderSpecialNoBanner && (
        <GenericNotification
          messageClass='generic-notification__notifications-on'
          title={utils.i18n('everyday_low_rate_apply_title', [utils.i18n('signin_flyout_alamo_insiders')])}
          message={utils.i18n('everyday_low_rate_apply_vehicle_message', [editTripDetailsAnchor], { jsx: true })}
        />
      )}

      <div className='vehicle-select__content'>
        {isDesktop && (
          <div className={filterWrapperClass}>
            <SortFilterWrapper
              filterFormRef={vehicleFilters}
              filterConfig={filters}
              sortConfig={sorting}
              storeKey={storeKey}
              open={shouldRenderFilters}
              theme={THEMES.DARKEST_BLUE}
              lowestCarFilterPrices={lowestCarFilterPrices}
              resultsCount={vehicles?.length}
              closeButtonLabel={utils.i18n('filter_view_results')}
              onClose={handleToggleFilter}
              vertical
              hasSorting
              hasFilters={hasFilters}
              ref={sortFilterWrapperRef}
              innerRef={handleOutsideClickSortFilterWrapperRef}
              destinationCurrencySelected={isPayTypeSelected === destinationCurrencyCode}
            />
            {shouldRenderFilters && (
              <VehicleFilterToggle onClick={handleToggleFilter} numFiltersSelected={numFiltersSelected} hasSorting />
            )}
          </div>
        )}

        <div className='vehicle-select__results'>
          <div className='vehicle-select__results-header'>
            {!isMobile && (
              <h3>{reservationDataAvailable && utils.i18n('vehicle_select_results_count', [vehicles?.length || 0])}</h3>
            )}
            {isMobile && pricingButton()}
            {isMobile && (
              <VehicleFilterToggle onClick={handleToggleFilter} numFiltersSelected={numFiltersSelected} hasSorting />
            )}
            {!isMobile && prepayTermsModalButton}
          </div>
          {isMobile && (
            <div className='vehicle-select__results-header-mobile'>
              <h3>{reservationDataAvailable && utils.i18n('vehicle_select_results_count', [vehicles?.length || 0])}</h3>
            </div>
          )}

          <div className={reservationDataAvailable && vehicles?.length ? 'vehicle-select__results-list' : null}>
            {isTablet && vehicles?.length && (
              <VehicleFilterToggle onClick={handleToggleFilter} numFiltersSelected={numFiltersSelected} hasSorting />
            )}
            {isTabletOrMobile && (
              <React.Fragment>
                <div className={filterWrapperClass}>
                  <SortFilterWrapper
                    filterFormRef={vehicleFilters}
                    filterConfig={filters}
                    sortConfig={sorting}
                    storeKey={storeKey}
                    open={shouldRenderFilters}
                    theme={THEMES.DARKEST_BLUE}
                    lowestCarFilterPrices={lowestCarFilterPrices}
                    resultsCount={vehicles?.length}
                    closeButtonLabel={utils.i18n('filter_view_results')}
                    onClose={handleToggleFilter}
                    vertical
                    hasSorting
                    hasFilters={hasFilters}
                    ref={sortFilterWrapperRef}
                    innerRef={handleOutsideClickSortFilterWrapperRef}
                    destinationCurrencySelected={isPayTypeSelected === destinationCurrencyCode}
                  />
                  {shouldRenderFilters && (
                    <VehicleFilterToggle
                      onClick={handleToggleFilter}
                      numFiltersSelected={numFiltersSelected}
                      hasSorting
                    />
                  )}
                </div>
              </React.Fragment>
            )}

            {reservationDataAvailable &&
              (vehicles?.length ? (
                <div>
                  {!showPromotionDoesNotApplyBanner &&
                    !showEverydayLowRatesBanner &&
                    (showCIDBanner || vehiclesHaveCoupon.all) && (
                      <div>
                        {/* && !alamoInsidersSpecialNotApplyBanner */}
                        {showCIDBanner && !showAlamoInsiderSpecialNoBanner && (
                          <DiscountNotification
                            type={contractDetails.contractType}
                            contractName={contractDetails.contractName}
                            defaultLoyaltyContract={hasDefaultLoyaltyContractProfile}
                          />
                        )}

                        {vehiclesHaveCoupon.all && (
                          <DiscountNotification type={coupons[0].type} contractName={coupons[0].description} />
                        )}
                      </div>
                    )}

                  {sortedVehicles.map((vehicle) => (
                    <VehicleSelectDetailsCard
                      key={vehicle.code}
                      vehicle={vehicle}
                      discountContractDetails={contractDetails}
                      hasDefaultLoyaltyContractProfile={hasDefaultLoyaltyContractProfile}
                      selected={vehicle.code === (selectedVehicle?.selected_car_class_code || preSelectedVehicle?.code)}
                      prepaySelected={prepaySelected}
                      prepayExistsInResults={prepayExists}
                      isTrueModify={isTrueModify}
                      isEuropeanUnionCountry={isEuropeanUnionCountry}
                      reservationCoupons={coupons}
                      isLastMinuteSpecials={isLastMinuteSpecials}
                      isPlanAheadSpecials={isPlanAheadSpecials}
                      vehicleSearchFilters={vehicleSearchFilters}
                      isPayTypeSelected={isPayTypeSelected}
                      destinationCurrencyCode={destinationCurrencyCode}
                      destinationCurrencySelected={isPayTypeSelected === destinationCurrencyCode}
                      showFeaturedVehicle={showFeaturedVehicle}
                    />
                  ))}
                </div>
              ) : (
                <NoResultsVehicleGrid clearFilters={clearFilters} />
              ))}
          </div>

          {!reservationDataAvailable ||
            (!hasFilters && (
              <div className='vehicle-select__loading-results'>
                <p>{utils.i18n('vehicle_select_loading_results')}</p>
                <LoadingWidget />
              </div>
            ))}
        </div>
      </div>

      <Modal
        modalKey={MODAL.CONVERTED_PRICES_MODAL}
        header={utils.i18n('rental_details_about_currency_conversion')}
        theme={MODAL_THEMES.WHITE}
      >
        <p className='modal-themed__copy'>{utils.i18n('converted_rates_modal_copy')}</p>
      </Modal>

      <Modal
        modalKey={MODAL.VAN_SELECTED_12_AND_15_TERMS_USE}
        header={utils.i18n('terms_use_van_model_main_title')}
        theme={MODAL_THEMES.WHITE}
      >
        <TermsOfUseVanModalContent />
      </Modal>

      <Modal modalKey={MODAL.TAXES_FEES_MODAL} theme={MODAL_THEMES.WHITE}>
        <TaxesFeesModalContent destinationCurrencySelected={isPayTypeSelected === destinationCurrencyCode} />
      </Modal>

      <LearnAboutPrepayAndPayLaterModal />

      <Modal
        modalKey={MODAL.LIMITED_INVENTORY_VEHICLE_MODAL}
        header={utils.i18n('limited_inventory_vehicle_modal_title')}
        theme={MODAL_THEMES.WHITE}
      >
        <LimitedInventoryVehicleModalContent />
      </Modal>
      <CurrencyConversionModal />
      {coupons && <CouponDoesNotApplyModal />}

      {showEverydayLowRatesBanner && <NoSpecialsAvailableModal isLMS={isLastMinuteSpecials} />}
    </div>
  );
};

VehicleSelect.propTypes = {
  // Container props
  breakpoint: PropTypes.object.isRequired,
  reservationDataAvailable: PropTypes.bool,
  contractDetails: PropTypes.object,
  vehicles: PropTypes.array,
  prepayExists: PropTypes.bool,
  handlePrepayTermsModal: PropTypes.func.isRequired,
  lowestCarFilterPrices: PropTypes.object, // TODO: need to record shape of this
  initVehicleSelectFilters: PropTypes.func.isRequired,
  clearFiltersUi: PropTypes.func.isRequired,
  selectedVehicle: PropTypes.object,
  preSelectedVehicle: PropTypes.object,
  numFiltersSelected: PropTypes.number,
  isTrueModify: PropTypes.bool,
  prepaySelected: PropTypes.bool,
  coupons: PropTypes.array.isRequired,
  vehiclesHaveCoupon: PropTypes.shape({
    all: PropTypes.bool,
    none: PropTypes.bool,
  }).isRequired,
  guaranteedVehiclesAvailable: PropTypes.bool,
  hasCarClasses: PropTypes.bool,
  hasFilters: PropTypes.bool,
  isEuropeanUnionCountry: PropTypes.bool,
  isLastMinuteSpecials: PropTypes.bool,
  isPlanAheadSpecials: PropTypes.bool,
  allVehicles: PropTypes.array,
  storeKey: PropTypes.string.isRequired,
};

export default VehicleSelect;
