import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import MODAL from 'constants/modals';
import { breakpointSelector } from 'selectors/breakpoint';
import { isNAPrepayModifySelector, hasSelectedVehicleSelector } from 'selectors/reservationFlowSelectors';
import { locationsSelector, locationsInitialValuesSelector } from 'selectors/progressBarSelectors';
import { getReservationInitiateError } from 'selectors/errorSelectors';
import { setPartnerRewardProgram, setPartnerRewardProgramMemberId } from 'actions/bookingWidget';
import { sessionReservationObjectSelector, getModifyFlag } from 'selectors/reservationSelectors';
import { clearVehiclesFilterData, setSelectedCurrencyToggleData } from 'actions/vehicleClass';
import { unsetLocationData } from 'actions/locationFinder';
import { getVehiclesList } from 'actions/vehicles';
import { initiateReservationFlow } from 'actions/reservation/initiateReservationFlow';
import { openModal } from 'actions/modal/openModal';
import { setIsGmiUIOneWayRental } from 'actions/reservation/setIsGmiUIOneWayRental';
import ProgressBarLocationDrawer from './ProgressBarLocationDrawer';

const mapStateToProps = (state) => {
  const reservationInitiateError = getReservationInitiateError(state);
  const isTrueModify = getModifyFlag(state);
  const reservationObj = sessionReservationObjectSelector(state);
  const partner_reward_program = reservationObj?.get('partner_reward_program');
  let values = locationsSelector(state);
  const initialValues = locationsInitialValuesSelector(state);
  if (isTrueModify && reservationInitiateError.length > 0) {
    values = initialValues;
  }

  return {
    breakpoint: breakpointSelector(state),
    initialValues,
    values,
    hasSelectedVehicle: hasSelectedVehicleSelector(state),
    isNAPrepayModify: isNAPrepayModifySelector(state),
    isTrueModify,
    partner_reward_program,
  };
};

const mapDispatchToProps = {
  setLocation: (location, exchangeType) =>
    actions.reservations.initiateState.set('location', { location, exchangeType }),
  unsetLocation: (exchangeType) => actions.reservations.initiateState.unset('location', { exchangeType }),
  onSubmit: () =>
    initiateReservationFlow({
      saveLatestSelections: true,
      preserveBundleProfile: true,
      shouldCheckAvailability: utils.config.getIsSoldOutTrueAvailability(),
    }),
  showCancelModal: () => openModal(MODAL.CONFIRM_CANCEL_RESERVATION_MODAL),
  clearVehiclesFilterData,
  getVehiclesList,
  setSelectedCurrencyToggleData,
  unsetLocationData,
  setPartnerRewardProgram,
  setPartnerRewardProgramMemberId,
  setIsGmiUIOneWayRental,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(utils.toJS(ProgressBarLocationDrawer)));
