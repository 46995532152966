import { connect } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import MODAL from 'constants/modals';
import {
  sessionReservationObjectSelector,
  getModifyFlag,
  isFetchingEndpointSelector,
  getSessionGboReservationExists,
} from 'selectors';
import {
  selectedPickupLocationSelector,
  selectedReturnLocationSelector,
  getOneWayRentalResFlow,
} from 'selectors/reservationSelectors';
import {
  isCounterBypassIneligibleSelector,
  isReservationFlowNotAllowedSelector,
  getDidPrepay,
} from 'selectors/reservationFlowSelectors';
import { getAppShowSoldOutNerabyLocations } from 'selectors/appSelectors';
import { getRentalTermsAndConditions } from 'actions/reservation/getRentalTermsAndConditions';
import { restoreGMIUIToInitiateState } from 'actions/reservation/restoreGMIUIToInitiateState';
import { openModal } from 'actions/modal/openModal';
import ReservationFlowWrapper from './ReservationFlowWrapper';

const mapStateToProps = (state) => {
  const gboReservationExists = getSessionGboReservationExists(state);
  const gmiReservationState = sessionReservationObjectSelector(state);

  return {
    one_way_rental: getOneWayRentalResFlow(state),
    pickup_location: selectedPickupLocationSelector(state),
    return_location: selectedReturnLocationSelector(state),
    isTrueModify: getModifyFlag(state),
    isGmiReservationStateEmpty: gmiReservationState === null,
    isFetchingSession: isFetchingEndpointSelector(state, { endpoint: 'session/current' }),
    gboReservationExists,
    isCounterBypassIneligible: isCounterBypassIneligibleSelector(state),
    isReservationFlowNotAllowed: isReservationFlowNotAllowedSelector(state),
    prepaySelected: getDidPrepay(state),
    showSoldOutNearbyLocations: getAppShowSoldOutNerabyLocations(state),
  };
};

const mapDispatchToProps = {
  hydrateUiReservationsState: actions.reservations.hydrateUiReservationsState,
  openCounterBypassIneligibleModal: () => openModal(MODAL.STC_ELIGIBILITY_LOSS),
  getRentalTermsAndConditions,
  sessionTimeoutModal: () => openModal(MODAL.SESSION_TIMEOUT_MODAL),
  restoreGMIUIToInitiateState,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ReservationFlowWrapper));
